import React, { Component } from 'react';
import { Form, Icon, Input, Button, Alert, Spin } from 'antd';

class ResetPasswordPage extends Component {
	state = {
		response: null,
		responseType: null,
		isPasswordMatch: null,
		confirmDirty: false,
		loading: false,
	};

	onSubmit = event => {
		event.preventDefault();
		const { actionCode, auth } = this.props;

		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({
					loading: true,
				});
				if (values.newpassword === values.confirmpassword) {
					auth.confirmPasswordReset(actionCode, values.newpassword)
						.then(() => {
							// console.log(resp);
							this.setState({
								response: true,
								responseType: 'success',
							});
						})
						.catch(e => {
							// console.log(e);
							this.setState({
								response: e,
								responseType: 'error',
							});
						});
				}
			}
			this.setState({
				loading: false,
			});
		});
	};

	handleConfirmBlur = e => {
		const value = e.target.value;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value });
	};

	compareToFirstPassword = (rule, value, callback) => {
		const form = this.props.form;
		if (value && value !== form.getFieldValue('newpassword')) {
			callback('Two passwords that you enter is inconsistent!');
		} else {
			callback();
		}
	};

	validateToNextPassword = (rule, value, callback) => {
		const form = this.props.form;
		if (value && this.state.confirmDirty) {
			form.validateFields(['confirmpassword'], { force: true });
		}
		callback();
	};

	renderInputFormItems = () => {
		const { getFieldDecorator } = this.props.form;
		return (
			<>
				<Form.Item label='New Password' hasFeedback>
					{getFieldDecorator('newpassword', {
						rules: [
							{ required: true, message: 'Please input your New Password!' },
							{
								validator: this.validateToNextPassword,
							},
						],
					})(
						<Input.Password
							prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
							type='password'
							placeholder='New Password'
						/>
					)}
				</Form.Item>
				<Form.Item label='Confirm Password' hasFeedback>
					{getFieldDecorator('confirmpassword', {
						rules: [
							{ required: true, message: 'Please input your Confirm New Password!' },
							{
								validator: this.compareToFirstPassword,
							},
						],
					})(
						<Input.Password
							prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
							type='password'
							placeholder='Confirm New Password'
							onBlur={this.handleConfirmBlur}
						/>
					)}
				</Form.Item>
				<Button block type='primary' htmlType='submit'>
					Submit
				</Button>
				<div style={{ paddingBottom: 20 }} />
			</>
		);
	};
	// componentWillMount = () => {
	// 	const { response } = this.state;
	// 	if (response) {
	// 		this.setState({
	// 			loading: false,
	// 		});
	// 	}
	// };

	render() {
		//console.log(this.props);
		const { response, responseType, loading } = this.state;

		return (
			<>
				<h1 style={{ textAlign: 'center' }}>RESET PASSWORD</h1>
				<Form onSubmit={this.onSubmit} hideRequiredMark>
					{!response && this.renderInputFormItems()}

					{loading ? (
						<Spin
							style={{
								marginTop: 20,
								display: 'flex',
								margin: 'auto',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						/>
					) : (
						response && (
							<Alert
								message={responseType.toUpperCase()}
								description={
									responseType === 'success'
										? 'Password changed, You can now sign in with your new password.'
										: response.message
								}
								type={responseType}
								showIcon
							/>
						)
					)}
				</Form>
			</>
		);
	}
}

export default Form.create()(ResetPasswordPage);
