import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ResetPasswordPage from './ResetPasswordPage';
import VerifyEmailPage from './VerifyEmailPage';
import { Row, Col } from 'antd';
import logo from '../assets/icon.png';
import queryString from 'query-string';
import { isEmpty } from 'lodash';

class EmailAction extends Component {
	state = {
		isValidURL: true,
		mode: null,
		actionCode: null,
		auth: null,
	};

	setMode = () => {
		const { location, auth } = this.props;
		const params = queryString.parse(location.search);

		const mode = params.mode;
		const actionCode = params.oobCode;

		if (!isEmpty(params)) {
			this.setState({
				mode,
				actionCode,
				auth,
			});
		} else {
			this.setState({
				isValidURL: false,
			});
		}
	};

	componentDidMount = () => {
		this.setMode();
	};

	render() {
		const { mode, isValidURL, actionCode, auth } = this.state;

		return (
			<Row>
				<Col xs={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} style={{ marginTop: 20 }}>
					<img
						style={{
							maxWidth: 100,
							display: 'flex',
							margin: '10px auto ',
						}}
						src={logo}
						alt='Logo'
					/>
					{isValidURL ? (
						mode === 'resetPassword' ? (
							<ResetPasswordPage actionCode={actionCode} auth={auth} />
						) : (
							<VerifyEmailPage actionCode={actionCode} auth={auth} />
						)
					) : (
						<div>
							<h1 style={{ textAlign: 'center' }}>Error Invalid URL</h1>
						</div>
					)}
				</Col>
			</Row>
		);
	}
}

export default withRouter(EmailAction);
