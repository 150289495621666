import React, { Component } from 'react';
import { Alert, Spin } from 'antd';

class VerifyEmailPage extends Component {
	state = {
		response: null,
		responseType: null,
	};

	handleVerifyEmail = async () => {
		const { auth, actionCode } = this.props;

		try {
			await auth.applyActionCode(actionCode);
			//console.log(res);
			this.setState({ response: true, responseType: 'success' });
		} catch (e) {
			//console.log(e);
			this.setState({ response: e, responseType: 'error' });
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		prevProps.actionCode !== this.props.actionCode && this.handleVerifyEmail();
	};

	render() {
		const { response, responseType } = this.state;

		return (
			<>
				<h1 style={{ textAlign: 'center' }}>VERIFY EMAIL</h1>
				<div
					style={{
						display: 'flex',
						margin: 'auto',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{response ? (
						<Alert
							message={responseType.toUpperCase()}
							description={
								responseType === 'success'
									? 'Your e-mail has been successfully verified.'
									: response.message
							}
							type={responseType}
							showIcon
						/>
					) : (
						<Spin style={{ marginTop: 15 }} />
					)}
				</div>
			</>
		);
	}
}

export default VerifyEmailPage;
