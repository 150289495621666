/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './App.css';
import firebase from '@firebase/app';
import '@firebase/auth';
import { BrowserRouter as Router } from 'react-router-dom';
import EmailAction from './components/EmailAction';

const App = () => {
	const app = firebase.initializeApp({
		apiKey: process.env.REACT_APP_FIREBASE_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
		databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	});
	const auth = app.auth();

	return (
		<Router>
			<EmailAction auth={auth} />
			{/* <Route path="/auth1" render={() => <Redirect
				to={{
					pathname: "/auth1.html"
				}}
			/>}
			/> */}
		</Router>
	);
};

export default App;

// const handleVerifyEmail = props => {};
